<template>
  <div>
    <slot name="ProgressBar"/>
    <page-header
      :title="`원하는 노래가 있으신가요?`"
    >
    </page-header>

    <div class="ui-border-line ui-mt-2" style="background-color: #e4e4e4"></div>

    <div class="ui-inputbox">
      <input type="text" v-model="wsong" placeholder="가수명-노래제목을 입력해주세요." required>
    </div>

    <div class="ui-border-line" style="height: 10px"></div>

    <check-box
      text="없어요. 전문가님이 추천해주세요!"
      :click="() => {
        if (wsong === '없어요. 전문가님이 추천해주세요!') {
          wsong = null
        } else {
          wsong = '없어요. 전문가님이 추천해주세요!'
        }
      }"
      :on="wsong === '없어요. 전문가님이 추천해주세요!'"
    >
    </check-box>

    <footer-box
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import CheckBox from '@/components/common/CheckBox'

export default {
  components: {
    PageHeader,
    FooterBox,
    CheckBox,
  },
  data() {
    return {
      wsong: null
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        return !this.wsong === false
      },
    },
  },
  methods: {
    nextStep() {
      this.postOffer = { wsong: this.wsong }
      this.$parent.nextStep()
    },
  },
}
</script>

<style lang="scss" scoped>
.search-list {
  .search-title {
    color: #b6b6b6;
    font-size: 1.6rem;
  }
  ul {
    li {
      margin: 2rem 0;
      .name {
        font-size: 2rem;
        color: #000;
      }
      .addr{
        font-size: 1.4rem;
        color: #b6b6b6;
      }
    }
  }
}
</style>
