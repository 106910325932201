var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("ProgressBar"),
      _c("page-header", { attrs: { title: "원하는 노래가 있으신가요?" } }),
      _c("div", {
        staticClass: "ui-border-line ui-mt-2",
        staticStyle: { "background-color": "#e4e4e4" }
      }),
      _c("div", { staticClass: "ui-inputbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.wsong,
              expression: "wsong"
            }
          ],
          attrs: {
            type: "text",
            placeholder: "가수명-노래제목을 입력해주세요.",
            required: ""
          },
          domProps: { value: _vm.wsong },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.wsong = $event.target.value
            }
          }
        })
      ]),
      _c("div", {
        staticClass: "ui-border-line",
        staticStyle: { height: "10px" }
      }),
      _c("check-box", {
        attrs: {
          text: "없어요. 전문가님이 추천해주세요!",
          click: function() {
            if (_vm.wsong === "없어요. 전문가님이 추천해주세요!") {
              _vm.wsong = null
            } else {
              _vm.wsong = "없어요. 전문가님이 추천해주세요!"
            }
          },
          on: _vm.wsong === "없어요. 전문가님이 추천해주세요!"
        }
      }),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }